export const FormScopes = {
  INDEX: 'index',
  CREATE: 'create',
  EDIT: 'edit',
  SHOW: 'show',
  DELETE: 'delete',
  DELETE_MULTIPLE: 'delete_multiple',
  OPEN_LIB: 'open_lib',
  SEND_TEST_EVENT: 'send_test_event',
};

export const stopPropagation = (callback) => (event) => {
  event.stopPropagation();

  return callback();
};
