import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { createContext, useMemo, useState, useReducer, useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import {
  deleteProductImageService,
  deleteProductService,
  getProductCategoriesService,
  getProductService,
  getAffiliateProductService,
  updateProductImageService,
  updateProductService,
  updateAffiliatePixelsService,
  getPaymentMethods,
} from '../services/products';

export const EditProductContext = createContext({
  product: {},
  isFetchingProduct: false,
  updateProduct: () => {},
  isUpdatingProduct: false,
  categories: [],
  isFetchingCategories: false,
  deleteProduct: () => {},
  isDeletingProduct: false,
  cancelSubscriptions: () => {},
  isCancelingSubscriptions: false,
  updatePixels: () => {},
  isUpdatingFixels: false,
  differentOffers: false,
  setDifferentOffers: () => {},
  firstError: null,
  setFirstError: () => {},
  paymentMethodsOptions: [],
  isLoadingPaymentMethodsOptions: false,
  onMovePaymentOption: () => {},
});

export const ProductType = {
  subscription: {
    value: 'subscription',
    label: 'Assinatura',
  },
  unique: {
    value: 'unique',
    label: 'Pagamento único',
  },
};

const initialState = {
  paymentMethodsOptions: [],
};

const reducer = (state, action) => {
  if (action.type === 'SET_PAYMENT_OPTION') {
    return {
      ...state,
      paymentMethodsOptions: action.payload,
    };
  }

  if (action.type === 'ON_MOVE_PAYMENT_OPTION') {
    const updatedArray = [...state.paymentMethodsOptions];
    const { from, to } = action.payload;

    const movedItem = updatedArray[from];

    updatedArray.splice(from, 1);
    updatedArray.splice(to, 0, movedItem);

    return {
      ...state,
      paymentMethodsOptions: updatedArray,
    };
  }

  return state;
};

const EditProductProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const queryClient = useQueryClient();

  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();

  const { id, short_id } = useParams();

  const { pathname } = useLocation();

  const isAffiliate = pathname.includes('affiliates');

  const [differentOffers, setDifferentOffers] = useState(false);
  const [firstError, setFirstError] = useState();

  const { data: product, isLoading: isFetchingProduct } = useQuery(
    ['product', { id, short_id }],
    () => (isAffiliate ? getAffiliateProductService({ short_id }) : getProductService({ id })),
    {
      enabled: !!id,
      initialData: undefined,
      select(data) {
        return {
          ...data,
          offers: data.offers?.map((offer) => ({
            ...offer,
            offerId: offer.id,
          })),
          bumps: data.bumps?.sort((a, b) => a.position - b.position),
        };
      },
    }
  );

  const { data: categories, isFetching: isFetchingCategories } = useQuery(
    ['categories'],
    () => getProductCategoriesService(),
    {
      initialData: [],
    }
  );

  const { mutateAsync: updatePixels, isLoading: isUpdatingFixels } = useMutation({
    mutationFn: async (tracking_pixels) => updateAffiliatePixelsService({ id, tracking_pixels }),
    onSuccess(data) {
      const productKey = ['product', { id, short_id }];

      const previous = queryClient.getQueryData(productKey);
      const newData = {
        ...previous,
        tracking_pixels: data,
      };

      queryClient.setQueryData(productKey, newData);
      enqueueSnackbar('Dados atualizados com sucesso', { variant: 'success' });
    },
    onError(error) {
      const fbErrorMessage = error?.response?.data?.facebook_pixels?.filter(
        (pixel) => Object.keys(pixel).length > 0
      );
      const errorMessage =
        fbErrorMessage[0]?.non_field_errors[0] ||
        error.message ||
        'Erro ao atualizar dados do produto';
      enqueueSnackbar(errorMessage, { variant: 'error' });
    },
  });

  const { mutateAsync: updateProduct, isLoading: isUpdatingProduct } = useMutation({
    mutationFn: async ({ image, paymentsOrder = [], ...data }) => {
      if (typeof image === 'string') {
        fetch(image)
          .then((response) => response.blob())
          .then(async (blob) => {
            const defaultImage = new File([blob], 'defaultProductCover.svg', {
              type: 'image/svg+xml',
            });

            await updateProductImageService({ id, image: defaultImage });
          });
      }
      if (image instanceof File) await updateProductImageService({ id, image });
      if (product?.image && !image) await deleteProductImageService({ id });

      const allData = {
        ...data,
        paymentsOrder: state.paymentMethodsOptions?.map(({ value }) => value),
        bumps: data?.bumps.map((bump, index) => ({
          ...bump,
          position: index,
        })),
        offers: data.offers
          ?.map((offer) => {
            if (!differentOffers)
              return {
                ...offer,
                delete: offer.default ? undefined : true,
              };

            return offer;
          })
          ?.map((offer) => {
            const { offerId, ...rest } = offer;

            return {
              ...rest,
              id: offerId || offer.id,
            };
          }),
      };

      return updateProductService({ ...allData, id });
    },
    onSuccess: async () => {
      enqueueSnackbar('Dados atualizados com sucesso', { variant: 'success' });
      queryClient.invalidateQueries('products');
    },
    onError: (error) => {
      queryClient.invalidateQueries('products');
      enqueueSnackbar(error.message ?? 'Erro ao atualizar dados do produto', { variant: 'error' });
    },
  });

  const { mutateAsync: deleteProduct, isLoading: isDeletingProduct } = useMutation(
    () => deleteProductService({ id }),
    {
      onSuccess: () => {
        enqueueSnackbar('Produto deletado com sucesso', { variant: 'success' });
        navigate('/dashboard/products');
      },
    },
    {
      onError: () => {
        enqueueSnackbar('Erro ao deletar produto', { variant: 'error' });
      },
    }
  );

  const { mutateAsync: cancelSubscriptions, isLoading: isCancelingSubscriptions } = useMutation(
    () => new Promise((resolve) => setTimeout(resolve, 1000)),
    {
      onSuccess: () => {
        enqueueSnackbar('Inscrições canceladas com sucesso', { variant: 'success' });
      },
    },
    {
      onError: () => {
        enqueueSnackbar('Erro ao cancelar inscrições', { variant: 'error' });
      },
    }
  );

  const hasFetchedOnce = useRef(false);

  const { isLoading: isLoadingPaymentMethodsOptions } = useQuery(
    ['paymentMethods', product?.id],
    getPaymentMethods,
    {
      enabled: !!product && !hasFetchedOnce.current,
      onSuccess: (values) => {
        hasFetchedOnce.current = true;
        function loadLabel(label) {
          return {
            pix: (
              <div className="flex flex-col items-center justify-center gap-2">
                <svg
                  fill="currentColor"
                  width="800px"
                  height="800px"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                  className="box-border h-8 w-8"
                >
                  <path d="M11.917 11.71a2.046 2.046 0 0 1-1.454-.602l-2.1-2.1a.4.4 0 0 0-.551 0l-2.108 2.108a2.044 2.044 0 0 1-1.454.602h-.414l2.66 2.66c.83.83 2.177.83 3.007 0l2.667-2.668h-.253zM4.25 4.282c.55 0 1.066.214 1.454.602l2.108 2.108a.39.39 0 0 0 .552 0l2.1-2.1a2.044 2.044 0 0 1 1.453-.602h.253L9.503 1.623a2.127 2.127 0 0 0-3.007 0l-2.66 2.66h.414z" />
                  <path d="m14.377 6.496-1.612-1.612a.307.307 0 0 1-.114.023h-.733c-.379 0-.75.154-1.017.422l-2.1 2.1a1.005 1.005 0 0 1-1.425 0L5.268 5.32a1.448 1.448 0 0 0-1.018-.422h-.9a.306.306 0 0 1-.109-.021L1.623 6.496c-.83.83-.83 2.177 0 3.008l1.618 1.618a.305.305 0 0 1 .108-.022h.901c.38 0 .75-.153 1.018-.421L7.375 8.57a1.034 1.034 0 0 1 1.426 0l2.1 2.1c.267.268.638.421 1.017.421h.733c.04 0 .079.01.114.024l1.612-1.612c.83-.83.83-2.178 0-3.008z" />
                </svg>{' '}
                {label}
              </div>
            ),
            boleto: (
              <div className="flex flex-col items-center justify-center gap-2">
                <svg
                  fill="currentColor"
                  width="800px"
                  height="800px"
                  viewBox="-4 0 32 32"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  className="box-border h-8 w-8"
                >
                  <path d="M0 25.281h0.781v-18.563h-0.781v18.563zM2.344 25.281h1.531v-18.563h-1.531v18.563zM5.406 25.281h1.563v-18.563h-1.563v18.563zM8.5 25.281h3.125v-18.563h-3.125v18.563zM13.156 25.281h2.344v-18.563h-2.344v18.563zM17.031 25.281h1.563v-18.563h-1.563v18.563zM20.125 25.281h0.781v-18.563h-0.781v18.563zM22.469 25.281h1.531v-18.563h-1.531v18.563z" />
                </svg>{' '}
                {label}
              </div>
            ),
            credit_card: (
              <div className="flex flex-col items-center justify-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                  className="box-border h-8 w-8"
                >
                  <path
                    fill="currentColor"
                    d="M2.5 4A1.5 1.5 0 001 5.5V6h18v-.5A1.5 1.5 0 0017.5 4h-15zM19 8.5H1v6A1.5 1.5 0 002.5 16h15a1.5 1.5 0 001.5-1.5v-6zM3 13.25a.75.75 0 01.75-.75h1.5a.75.75 0 010 1.5h-1.5a.75.75 0 01-.75-.75zm4.75-.75a.75.75 0 000 1.5h3.5a.75.75 0 000-1.5h-3.5z"
                    clipRule="evenodd"
                  />
                </svg>{' '}
                {label}
              </div>
            ),
            picpay: (
              <div className="flex flex-col items-center justify-center gap-2">
                <svg
                  width="25"
                  height="22"
                  viewBox="0 0 25 22"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.963 0.586975V8.12397H24.5V0.586975H16.963ZM18.219 1.84298H23.244V6.86798H18.219V1.84298ZM19.475 3.09898V5.61198H21.988V3.09898H19.475ZM4.27 4.35498V7.52997H7.646C9.788 7.52997 11.004 8.56997 11.004 10.469C11.004 12.416 9.788 13.48 7.646 13.48H4.269L4.27 7.52997H0.5V21.414H4.269V16.654H7.839C12.172 16.654 14.654 14.302 14.654 10.334C14.654 6.56297 12.172 4.35598 7.84 4.35598L4.27 4.35498Z"
                    fill="currentColor"
                  />
                </svg>{' '}
                {label}
              </div>
            ),
          };
        }

        const { paymentsOrder } = product;

        const result = values
          ?.sort((a, b) => paymentsOrder.indexOf(a.type) - paymentsOrder.indexOf(b.type))
          .map(({ type, name }) => ({
            value: type,
            label: loadLabel(name)[type] || name,
            name,
          }));

        dispatch({
          type: 'SET_PAYMENT_OPTION',
          payload: result,
        });
      },
    }
  );

  const onMovePaymentOption = useCallback((removedIndex, addedIndex) => {
    dispatch({
      type: 'ON_MOVE_PAYMENT_OPTION',
      payload: {
        from: removedIndex,
        to: addedIndex,
      },
    });
  }, []);

  const value = useMemo(
    () => ({
      product,
      isFetchingProduct,
      updateProduct,
      categories,
      isFetchingCategories,
      isUpdatingProduct,
      deleteProduct,
      isDeletingProduct,
      cancelSubscriptions,
      isCancelingSubscriptions,
      updatePixels,
      isUpdatingFixels,
      differentOffers,
      setDifferentOffers,
      firstError,
      setFirstError,
      paymentMethodsOptions: state.paymentMethodsOptions,
      isLoadingPaymentMethodsOptions,
      onMovePaymentOption,
    }),
    [
      product,
      isFetchingProduct,
      updateProduct,
      categories,
      isFetchingCategories,
      isUpdatingProduct,
      deleteProduct,
      isDeletingProduct,
      cancelSubscriptions,
      isCancelingSubscriptions,
      updatePixels,
      isUpdatingFixels,
      differentOffers,
      setDifferentOffers,
      firstError,
      setFirstError,
      isLoadingPaymentMethodsOptions,
      state,
      onMovePaymentOption,
    ]
  );

  return <EditProductContext.Provider value={value}>{children}</EditProductContext.Provider>;
};

EditProductProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default EditProductProvider;
