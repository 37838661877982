import { AuthenticatedHttp } from '../../http/axios';

export const getProductsService = async ({
  search = '',
  status = 'active',
  page = 1,
  limit = 10,
  contentDelivery,
}) =>
  AuthenticatedHttp.get('/products/', {
    params: {
      page,
      search,
      limit,
      contentDelivery,
      ...(status !== 'all' && { status }),
    },
  }).then(({ data: { results, count } }) => ({
    products: results,
    count,
  }));

export const getProductService = async ({ id }) =>
  AuthenticatedHttp.get(`/product/${id}/`).then(({ data: product }) => product);

export const getPublicProductService = async ({ id }) =>
  AuthenticatedHttp.get(`/product/${id}/public/`).then(({ data: product }) => product);

export const getAffiliateProductService = async ({ short_id }) =>
  AuthenticatedHttp.get(`affiliates/products/${short_id}/`).then(({ data: product }) => product);

export const getProductCategoriesService = async () =>
  AuthenticatedHttp.get('/product/categories/').then(({ data: { success, categories } }) => {
    if (!success) {
      throw new Error('Erro ao buscar categorias');
    }
    return categories;
  });

export const createProductService = async ({ ...info }) =>
  AuthenticatedHttp.post('/products/', {
    ...info,
  }).then(({ data: product }) => product);

export const updateProductService = async ({ id, ...info }) =>
  AuthenticatedHttp.put(`/product/${id}/`, info).then(({ data: product }) => product);

export const updateAffiliatePixelsService = async ({ id, tracking_pixels }) =>
  AuthenticatedHttp.put(`/pixels/tracking_pixels/${tracking_pixels.id}/`, tracking_pixels).then(
    ({ data: product }) => product
  );

export const updateProductImageService = async ({ id, image }) => {
  const formData = new FormData();

  formData.append('image', image);

  return AuthenticatedHttp.put(`/product/${id}/image/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }).then(({ data }) => {
    if (data.detail) return data;
    throw new Error('Erro ao atualizar imagem do produto');
  });
};

export const deleteProductImageService = async ({ id }) =>
  AuthenticatedHttp.delete(`/product/${id}/image/`).then(({ data }) => {
    if (data.detail) return data;
    throw new Error('Erro ao deletar imagem do produto');
  });

export const deleteProductService = async ({ id }) => AuthenticatedHttp.delete(`/product/${id}/`);

export const getPaymentMethods = async () =>
  AuthenticatedHttp.get('/products/payment_methods/')
    .then(({ data }) => data)
    .catch(() => []);
